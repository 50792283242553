<template>
  <div class="nav">
    <el-upload :headers="{ Token: token }" :data="{ type: 'img' }" :show-file-list="false" class="upImg"
      :on-success="handleAvatarSuccess" action="https://www.bkgaoshou.com/api/upload/upload">
      <img v-if="headImg" :src="headImg" class="headImg" alt />
      <span v-else class="headImg">上传头像</span>
    </el-upload>
    <div class="name">
      <router-link to="/editExpert" style="font-size: 18px">{{
        ExpertInfo.name
      }}</router-link>
    </div>
    <el-menu default-active="2" class="el-menu-vertical-demo" :router="true" style="font-size: 16px">
      <el-submenu index="1">
        <template slot="title">
          <span class="blue">学生管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1" route="/user?type=4">
            <img src="@/assets/icon/icon (2).png" alt="" />
            一对一用户
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="1-2" route="/reviewed"><img src="@/assets/icon/icon (3).png" alt="" />志愿审核用户
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="1-3" route="/vipUser"><img src="@/assets/icon/icon (4).png" alt="" />VIP卡用户
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="1-4" route="/user?type=0"><img src="@/assets/icon/icon (5).png" alt="" />普通用户
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="1-6" route="/addUser"><img src="@/assets/icon/icon49.png" alt="" />自建用户</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="6">
        <template slot="title">
          <span class="blue">专家模式</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="6-1" route="/SearchSchool"><img src="@/assets/icon/icon40.png" alt="" />查院校
          </el-menu-item>
        </el-menu-item-group>

        <el-menu-item-group>
          <el-menu-item index="6-2" route="/SearchSpecialty"><img src="@/assets/icon/icon41.png" alt="" />查专业
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="6-6" route="/pdf_plan/plan"><i class="el-icon-user" style="margin-left: -4px"></i>查计划
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="6-7" route="/pdf_plan/zhengce"><i class="el-icon-user" style="margin-left: -4px"></i>查政策
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="6-5" route="/filling"><img src="@/assets/icon/icon40.png" alt="" />创建志愿</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2" v-if="parent_id == 0">
        <template slot="title">
          <span class="blue">导师管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="2-1" route="/expert/expert"><i class="el-icon-user" style="margin-left: -4px"></i>添加导师
          </el-menu-item>
          <el-menu-item index="1-2" route="/expert_list"><img src="@/assets/icon/icon (6).png" alt="" />导师库
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <!-- <el-submenu index="3">
        <template slot="title">
          <span class="blue">卡片管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="3-3" route="/rule" v-if="parent_id == 0"
            ><img
              src="@/assets/icon/icon (7).png"
              alt=""
            />发卡权限</el-menu-item
          >
          <el-menu-item index="3-1" route="/create_card"
            ><img
              src="@/assets/icon/icon (8).png"
              alt=""
            />添加新卡</el-menu-item
          >
          <el-menu-item index="3-2" route="/card_list"
            ><img
              src="@/assets/icon/icon (9).png"
              alt=""
            />卡片记录</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu> -->
      <!-- <el-submenu index="5" v-if="parent_id == 0">
        <template slot="title">
          <span class="blue">系统设置</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="5-1" route="/banner"
            ><img
              src="@/assets/icon/icon (21).png"
              alt=""
            />网站设置</el-menu-item
          >
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="5-2" route="/system_news"
            ><img
              src="@/assets/icon/icon (22).png"
              alt=""
            />资讯列表</el-menu-item
          >
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="5-3" route="/service"
            ><img
              src="@/assets/icon/icon (22).png"
              alt=""
            />服务信息</el-menu-item
          >
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="5-4" route="/about_us"
            ><img
              src="@/assets/icon/icon (22).png"
              alt=""
            />系统设置</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu> -->
      <!-- <el-submenu index="4" v-if="parent_id == 0">
        <template slot="title">
          <span class="blue">商品管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="4-1" route="/goods"
            ><img
              src="@/assets/icon/icon (27).png"
              alt=""
            />商品列表</el-menu-item
          >
        </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item index="4-2" route="/order"
            ><i class="el-icon-s-order"></i>订单列表</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      headImg: "",
      token: localStorage.getItem("TOKEN"),
      ExpertInfo: [],
      parent_id: -1,
    };
  },

  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.parent_id = this.ExpertInfo.parent_id;
    this.headImg = this.ExpertInfo.logo;
    if (!this.headImg) {
      this.headImg =
        "https://img.bkgaoshou.com/img/20210518/684a578730d58db17bc4fb4e987ab49f.jpg";
    }
  },
  methods: {
    handleAvatarSuccess(res) {
      if (res.code === "200") {
        this.headImg = res.data;
        this.editLogo(res.data);
      } else {
        this.$notify({
          showClose: true,
          message: "上传图片失败！",
          type: "error",
        });
      }
    },
    editLogo(url) {
      this.$fecth
        .post("login/editLogo", {
          id: this.ExpertInfo.id,
          logo: url,
        })
        .then((res) => {
          console.log(res);
          this.ExpertInfo.logo = url;
          this.ExpertInfo = JSON.stringify(this.ExpertInfo);
          localStorage.setItem("ExpertInfo", this.ExpertInfo);
          this.$message({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
        });
    },
  },
};
</script>

<style scoped lang='less'>
.nav {
  display: inline-block;
  width: 200px;

  margin-bottom: 10px;
  min-height: 800px;
  background: white;

  box-shadow: 1px 0 0 0 #e6dddd;
  z-index: 1;

  .blue {
    color: #5a7dfb;
  }

  .upImg {
    text-align: center;
  }

  .name {
    text-align: center;
    margin-bottom: 20px;
  }

  .headImg {
    width: 100px;
    height: 100px;
    line-height: 100px;
    background: gray;
    margin: 40px auto 20px;
    display: block;
    border-radius: 50%;
  }
}
</style>

<style lang="less">
.el-menu-vertical-demo {
  .el-menu-item-group__title {
    padding: 0;
  }

  img {
    margin-right: 15px;
  }
}

.el-submenu__title {
  font-size: 16px;
}

.el-menu-item {
  font-size: 16px;
}

.zijian {
  background: #409EFF;

  .el-submenu__title:hover {
    background: #0051a1;
  }

  color: white;

  .nav .blue {
    color: white !important;
  }
}
</style>
